import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MESSAGES } from '@shared/constants';
import { finalize } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styles: ``
})
export class UnauthorizedAccessComponent {

  MESSAGES = MESSAGES;
  isCustomerEnabled: boolean;

  constructor(
    private authService: AuthenticationService,
    private r: Router) {
      this.getUserStatus();
    }

  goToLogin(): void {
    this.authService.logout()
      .pipe(finalize(() => this.r.navigateByUrl('/login'))).subscribe();
  }

  getUserStatus(): void {
    this.authService.getMe()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.isCustomerEnabled = user?.customerEnabled;
      });
  }

}
